@import './colors.scss';
$boxShadow: 0 10px 30px -12px rgba($blackColor, 0.42),
    0 4px 25px 0px rgba($blackColor, 0.12),
    0 8px 10px -5px rgba($blackColor, 0.2);
$primaryBoxShadow: 0 4px 20px 0 rgba($blackColor, 0.14),
    0 7px 10px -5px rgba($purpleColor1, 0.4);
$infoBoxShadow: 0 4px 20px 0 rgba($blackColor, 0.14),
    0 7px 10px -5px rgba($infoColor1, 0.4);
$successBoxShadow: 0 4px 20px 0 rgba($blackColor, 0.14),
    0 7px 10px -5px rgba($successColor1, 0.4);
$warningBoxShadow: 0 4px 20px 0 rgba($blackColor, 0.14),
    0 7px 10px -5px rgba($warningColor1, 0.4);
$dangerBoxShadow: 0 4px 20px 0 rgba($blackColor, 0.14),
    0 7px 10px -5px rgba($dangerColor1, 0.4);
$roseBoxShadow: 0 4px 20px 0 rgba($blackColor, 0.14),
    0 7px 10px -5px rgba($roseColor1, 0.4);

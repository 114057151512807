@import '../../assets/scss/colors.scss';

.loading-container {
    width: 100%;
    position: fixed;
    height: 100vh;
    background-color: $darkSlateGrey-90;
    top: 0;
    left: 0;
    z-index: 9999;
}
.cs-loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.cs-loader-inner {
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
    width: calc(100% - 200px);
    color: $white;
    padding: 0 100px;
    text-align: center;
}

.cs-loader-inner label {
    // font-size: 2rem;
    opacity: 0;
    display: inline-block;
}

@keyframes lol {
    0% {
        opacity: 0;
        transform: translateX(-300px);
    }
    33% {
        opacity: 1;
        transform: translateX(0px);
    }
    66% {
        opacity: 1;
        transform: translateX(0px);
    }
    100% {
        opacity: 0;
        transform: translateX(300px);
    }
}

@-webkit-keyframes lol {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-300px);
    }
    33% {
        opacity: 1;
        -webkit-transform: translateX(0px);
    }
    66% {
        opacity: 1;
        -webkit-transform: translateX(0px);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(300px);
    }
}

.cs-loader-inner label:nth-child(6) {
    -webkit-animation: lol 3s infinite ease-in-out;
    animation: lol 3s infinite ease-in-out;
}

.cs-loader-inner label:nth-child(5) {
    -webkit-animation: lol 3s 100ms infinite ease-in-out;
    animation: lol 3s 100ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(4) {
    -webkit-animation: lol 3s 200ms infinite ease-in-out;
    animation: lol 3s 200ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(3) {
    -webkit-animation: lol 3s 300ms infinite ease-in-out;
    animation: lol 3s 300ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(2) {
    -webkit-animation: lol 3s 400ms infinite ease-in-out;
    animation: lol 3s 400ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(1) {
    -webkit-animation: lol 3s 500ms infinite ease-in-out;
    animation: lol 3s 500ms infinite ease-in-out;
}

@import '../../assets/scss/colors.scss';

footer {
    background-color: #4e8d63;
    z-index: 1100;
    border-top: 1px solid rgb(64, 113, 80);
    @media only screen and (max-width: 959px) {
        z-index: 1;
    }
}
footer h6.MuiTypography-root.MuiTypography-h6,
footer p.MuiTypography-root.MuiTypography-body1 {
    color: $white;
}
footer p.MuiTypography-root {
    margin-top: 1rem;
}
footer .MuiListItem-root {
    padding-top: 0;
    padding-bottom: 0;
}
footer p.MuiTypography-root:last-child {
    margin-bottom: 1rem;
}

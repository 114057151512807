.profileContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.profileContainer h6.MuiTypography-h6 {
    font-weight: bold;
    line-height: 1.5em;
    // font-size: 1em;
    text-transform: uppercase;
    letter-spacing: 1px;
}

@import '../../assets/scss/colors.scss';
.sidebarContainer {
    background-image: linear-gradient(to bottom, $coral, $coral-80),
        url('../../assets/images/halko.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    min-height: 80vh;
    width: 20%;
    padding: 0;
    margin: 0;
}
.sidebarContainer .MuiList-root {
    background-color: transparent;
}
.sideBarList .MuiList-root *,
.sideBarList .MuiListItemIcon-root > .MuiSvgIcon-root,
.sidebarContainer .MuiTypography-body1,
.sidebarContainer .MuiListSubheader-root,
.sidebarContainer .MuiListItemIcon-root,
.sidebarContainer .MuiSvgIcon-root {
    font-weight: 600;
    // font-size: 1.6rem;
    line-height: 1em;
    color: $white;
}
.sidebarContainer .MuiListSubheader-root {
    position: sticky;
    top: 7.3rem;
    background-color: $coral;
}

.sidebarContainer .MuiListItem-root {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
#nested-list-subheader {
    margin: 0;
    padding: 16px 0;
    // font-size: 1.6rem;
    line-height: 1.5em;
}

.sellers {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    &--showProductsButton {
        position: fixed;
        z-index: 9;
        bottom: 2rem;
        left: 2rem;
        width: 26rem;
        height: 6rem;
        & svg {
            margin-right: 0.5rem;
        }
    }
    &--showSellersButton {
        border-radius: 0;
        height: 6rem;
    }
    &--mapButton {
        background: #ffffff;
        box-shadow: 0 2px 4px rgba(0,0,0,.1);
        z-index: 10;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
}

@import '../../assets/scss/colors.scss';

.MuiTypography-root.margin-left-1 {
    margin-left: 1rem;
}
.wood-bg {
    background-color: $forestGreen !important;
    /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.52)), url("/images/halko.jpg"); */
}

header .MuiIconButton-root a:hover,
header .MuiIconButton-root a:active {
    background-color: transparent;
}

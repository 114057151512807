@import '../../../assets/scss/colors.scss';

.beige {
    background-color: $beige;
}
.MuiGrid-item.mb-2 {
    margin-bottom: 2rem;
}

.form {
    &_inputContainer {
        padding: 0.5rem !important;
    }
}

.image {
    width: 100%;
    min-height: 30vh;
    background-size: cover !important;
    height: auto;
}
.content {
    padding: 2rem 0;
    display: inline-block;
    width: 100%;
}

@import '../../assets/scss/colors.scss';
.tableIcon {
    &_orange {
        color: $coral;
    }
    &_red {
        color: $darkRed;
    }
    &_green {
        color: $forestGreen;
    }
    &_green80 {
        color: $forestGreen80;
    }
}

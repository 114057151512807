@import '../../assets/scss/colors.scss';

#frontPageHero {
    height: 50vh;

    @media only screen and (max-width: 660px) {
        height: 65vh;
    }

    @media only screen and (max-width: 340px) {
        height: 75vh;
    }

    width: 100%;
    padding: 0;
    // To change to the previous image change the url to: https://cdn.pixabay.com/index/2019/12/10/13-14-35-755_1440x480.jpg
    background-image: linear-gradient(
            rgba(1, 111, 56, 0.1),
            rgba(1, 111, 56, 0.4)
        ),
        url('../../assets/images/hero.jpeg');
    background-position: center;
    background-size: cover;
}

.MuiIconButton-root {
    border-radius: 5px !important;
}

.MuiPaper-root .MuiCardHeader-root.p-05 {
    padding: 0.5rem;
}

.heroContent {
    height: 40vh;

    @media only screen and (max-width: 660px) {
        height: 50vh;
    }

    @media only screen and (max-width: 340px) {
        height: 65vh;
    }
}

.heroContent.transparentBg.MuiPaper-root {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 1rem 2rem;
}

.beigeBg {
    background-color: $lavender;
}

.cardMediaHeight {
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 35vh;
}

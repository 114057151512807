@import '../../../assets/scss/colors.scss';

.productCard {
    .success {
        box-shadow: 0 0 2px forestgreen;
    }
    .danger {
        box-shadow: 0 0 2px red;
    }
}
.pagination {
    .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
        display: flex;
    }
}
.userCard * {
    text-align: left;
}
.userCard {
    padding: 2rem;
}

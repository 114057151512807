@import '../../assets/scss/colors.scss';
.center {
    h2.MuiTypography-root.MuiTypography-h6 {
        text-align: center;
    }
}
.dialogTitleSeller {
    padding: 0 !important;
    height: 7rem;
    &_container {
        height: 7rem;
        &__item {
            height: 7rem;
            padding-top: 1.5rem !important;
            &___box {
                &____text {
                    color: $white;
                }
            }
        }
    }
}
.colorWhite {
    * {
        color: $white !important;
    }
}
.closeModalContainer {
    background-color: $grayColor-11;
    height: 7rem;
    display: flex;
    &_box {
        height: 7rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &_boxPurpleColor {
        background-color: $purpleColor3;
        border-bottom: 0.5rem solid $purpleColor3-80;
    }
    &_boxOrange {
        background-color: $warningColor1;
        border-bottom: 0.5rem solid $warningColor1-80;
    }
}
.sellerModalContainer {
    padding: 0 !important;
    min-height: 80vh;
    &_infoContainer {
        background-color: $white-Smoke;
        &__sellerInfoHeading {
            padding: 1rem;
            width: 100%;
            text-align: center;
            * {
                color: $white-Smoke;
            }
        }
        &__purpleColor {
            background-color: $purpleColor3-80 !important;
            border-top: 0.5rem solid $purpleColor3 !important;
        }
        &__orange {
            background-color: $warningColor1-80 !important;
            border-top: 0.5rem solid $warningColor1 !important;
        }
    }
}
.sellerInfoList {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    &_item {
        padding-left: 0 !important;
    }
}
.link {
    color: $grayColor2;
    text-decoration: none;
}
.sellerModalBg {
    padding: 0;
    margin: 0;
    width: 100%;
    &_purpleColor {
        background-color: $purpleColor3-80;
        border-bottom: 0.5rem solid $purpleColor3;
    }
    &_teal {
        background-color: $lightSeaGreen-80;
        border-bottom: 0.5rem solid $lightSeaGreen;
    }
    &_orange {
        background-color: $warningColor1-80;
        border-bottom: 0.5rem solid $warningColor1;
    }
    margin-bottom: 1rem;
    .MuiAvatar-colorDefault {
        background-color: $white;
    }
}

@import '../../assets/scss/colors.scss';

.productContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.products-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 80%;
}
.productsListHeader {
    width: 100%;
    padding: 24px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
    max-height: 24px;
}

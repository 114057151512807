@import '../../assets/scss/colors.scss';
.text-left {
    text-align: left;
}
.header-box {
    padding: 2rem;
}
.a4Doc {
    min-height: 90rem;
}
.clickableItem {
    cursor: pointer;
    &:focus {
        outline: none;
        box-shadow: 0 0 3px 4px steelblue;
    }
}
.royalBlue {
    background-color: $royalBlue80;
    border-bottom: 1px solid $royalBlue;
    h6.MuiTypography-root.MuiTypography-h6,
    p {
        color: $white;
    }
}
.sandyBrown {
    background-color: rgba($sandyBrown, 0.8);
    border-bottom: 1px solid $sandyBrown;
    h6.MuiTypography-root.MuiTypography-h6,
    p {
        color: $white;
    }
}
.coral {
    background-color: $coral-80;
    border-bottom: 1px solid $coral;
    h6.MuiTypography-root.MuiTypography-h6,
    p {
        color: $white;
    }
}
.forestGreen {
    background-color: $forestGreen80;
    border-bottom: 1px solid $forestGreen;
    h6.MuiTypography-root.MuiTypography-h6,
    p {
        color: $white;
    }
}
.flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    h6 {
        margin-left: 2rem;
    }
}

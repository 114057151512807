@import '../../assets/scss/colors.scss';
.MuiPaper-root.MuiCard-root.productCard {
    // margin: 0 auto;
    width: 100%;
    .MuiCardHeader-root.cardHeader {
        &_purpleColor {
            background-color: $purpleColor3-80;
            border-bottom: 0.5rem solid $purpleColor3;
        }
        &_teal {
            background-color: $lightSeaGreen-80;
            border-bottom: 0.5rem solid $lightSeaGreen;
        }
        &_orange {
            background-color: $warningColor1-80;
            border-bottom: 0.5rem solid $warningColor1;
        }
        padding: 2rem 3rem;
        margin-bottom: 1rem;
        .MuiAvatar-colorDefault {
            background-color: $white;
        }
        .sellerCardTitle {
            color: $white;
        }
        .MuiAvatar-colorDefault .colorDefault {
            background-color: $white;
            &_purpleColor {
                background-color: $purpleColor3-80;
                border-bottom: 0.5rem solid $purpleColor3;
            }
            &_teal {
                background-color: $lightSeaGreen-80;
                border-bottom: 0.5rem solid $lightSeaGreen;
            }
            &_orange {
                background-color: $warningColor1-80;
                border-bottom: 0.5rem solid $warningColor1;
            }
        }
        .MuiCardHeader-content
            > h5.MuiTypography-root.productCardTitle.MuiTypography-h5,
        .MuiCardHeader-content
            > h6.MuiTypography-root.productCardTitle.MuiTypography-h6 {
            color: $white;
        }
    }
    .content {
        .MuiTypography-body1 {
            font-size: 2rem;
        }
    }
}
button.MuiButtonBase-root.MuiIconButton-root.addToCartButton {
    color: $lightSeaGreen;
    span.MuiIconButton-label > svg.MuiSvgIcon-root {
        fill: $lightSeaGreen;
    }
}
.MuiList-root.MuiList-padding {
    padding: 0;
    .MuiListItemText-root {
        margin: 0;
    }
}

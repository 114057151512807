@import '../../assets/scss/colors.scss';

.App {
    text-align: center;
}
p.MuiTypography-root.MuiTypography-body1 {
    line-height: 1.6;
    // font-size: 1.8rem;
}
section,
main {
    background: $white-Smoke;
}
.black {
    color: $darkSlateGrey;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary {
    color: $forestGreen;
    border: 1px solid $forestGreen;
    &:hover {
        background-color: $lavender;
    }
    span.MuiButton-outlinedSecondary {
        color: $forestGreen;
    }
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedSecondary {
    color: $coral;
    border: 1px solid $coral;
    span.MuiButton-outlinedSecondary {
        color: $coral;
    }
}
.clickable {
    cursor: pointer;
}

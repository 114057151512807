@import '../../assets/scss/colors.scss';
.showProfileButton {
    background-color: $mediumSeaGreen;
    color: $white;
    font-size: 1.6rem;
    letter-spacing: 1px;
    border: none;
    border-radius: 4px;
    padding: 0.8rem 1.5rem;
    cursor: pointer;
    &:hover {
        background-color: darken($mediumSeaGreen, 10%);
    }
}

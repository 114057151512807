$beige: beige;
$linen: #efefe7;
$darkKhaki: #b89265;
$sandyBrown: rgb(239, 179, 75);
$sandyBrown80: rgba(239, 179, 75, 0.8);
$khakiGreen: #97bd62;
$coral: #fe7f46;
$coral-80: rgba(254, 127, 70, 0.8);
$royalBlue: rgb(63, 102, 218);
$royalBlue80: rgba(63, 102, 218, 0.8);
$lightSeaGreen-80: rgba(#299e96, 0.8);
$lightSeaGreen: #299e96;
$lavender: #def2f1;
$white-Smoke: #f2f8f8;
$white: #ffffff;
$mediumSeaGreen: #36d391;
$forestGreen: rgb(0, 112, 56);
$forestGreen80: rgba(0, 112, 56, 0.8);
$greenLime: #8b9050;
$darkSlateGrey: #2d3544;
$darkSlateGrey-80: rgba(45, 53, 68, 0.8);
$darkSlateGrey-90: rgba(45, 53, 68, 0.9);
$powderBlue: #b4ebd4;
$indianRed-80: rgba(#c9414e, 0.8);
$indianRed: #c9414e;
$darkRed: #f50057;
$veryDarkRed: darkred;
$veryDarkRed80: rgba(darkred, 0.8);

/** Admin panel colors */
$purpleColor1: #9c27b0;
$purpleColor2: #ab47bc;
$purpleColor3: #8e24aa;
$purpleColor3-80: rgba(#8e24aa, 0.8);
$purpleColor4: #af2cc5;
$warningColor1-80: rgba(#ff9800, 0.8);
$warningColor1: #ff9800;
$warningColor2: #ffa726;
$warningColor3: #fb8c00;
$warningColor4: #ffa21a;
$successColor1: #4caf50;
$successColor2: #66bb6a;
$successColor3: #43a047;
$successColor4: #5cb860;
$dangerColor1: #f44336;
$dangerColor2: #ef5350;
$dangerColor3: #e53935;
$dangerColor4: #f55a4e;
$infoColor1: #00acc1;
$infoColor2: #26c6da;
$infoColor3: #00acc1;
$infoColor4: #00d3ee;
$roseColor1: #e91e63;
$roseColor2: #ec407a;
$roseColor3-80: rgba(#d81b60, 0.8);
$roseColor3: #d81b60;
$roseColor4: #eb3573;
$grayColor1: #999;
$grayColor2: #777;
$grayColor3: #3c4858;
$grayColor4: #aaaaaa;
$grayColor5: #d2d2d2;
$grayColor6: #ddd;
$grayColor7: #b4b4b4;
$grayColor8: #555555;
$grayColor9: #333;
$grayColor-10: #a9afbb;
$grayColor-11: #eee;
$grayColor-12: #e7e7e7;

$blackColor: #000000;
$whiteColor: #ffffff;

$fontLato: 'Lato';

@import '../../assets/scss/colors.scss';

.mapModal {
    padding: 0;
    max-height: 100vh;
    &__header {
        padding: 0 !important;
        background-color: $white;
        box-shadow: 0 0 5px 4px rgba($forestGreen, 0.5);
        &__container {
            padding: 0 !important;
            &__item {
                padding: 0 !important;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
            }
        }
    }
    &__body {
        padding: 0 !important;
        &__sellers {
            overflow-y: scroll;
            max-height: 90vh;
        }
        &__mapGrid {
            width: 100%;
            position: relative;
            height: 90vh;
        }
    }
}

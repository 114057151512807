@import '../../assets/scss/colors.scss';

.coral {
    background-color: $coral;
}
h4.MuiTypography-root.MuiTypography-h4.MuiTypography-colorTextPrimary.MuiTypography-gutterBottom.MuiTypography-alignCenter,
p.MuiTypography-root.MuiTypography-h6.MuiTypography-colorTextPrimary.MuiTypography-alignCenter {
    font-weight: 500;
    color: $white;
}

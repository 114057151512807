@import '../../assets/scss/colors.scss';
.MuiPaper-root.MuiCard-root.productCard {
    // margin: 0 auto;
    width: 100%;
    .MuiCardHeader-root.cardHeader {
        &_coral {
            background-color: $coral-80;
            border-bottom: 0.5rem solid $coral;
        }
        &_teal {
            background-color: $lightSeaGreen-80;
            border-bottom: 0.5rem solid $lightSeaGreen;
        }
        &_indianred {
            background-color: $indianRed-80;
            border-bottom: 0.5rem solid $indianRed;
        }
        padding: 2rem 3rem;
        margin-bottom: 1rem;
        .MuiAvatar-colorDefault {
            background-color: $white;
        }
        .MuiAvatar-colorDefault .colorDefault {
            background-color: $white;
            &_coral {
                color: $coral-80;
            }
            &_teal {
                color: $lightSeaGreen-80;
            }
            &_indianred {
                color: $indianRed-80;
            }
        }
        .MuiCardHeader-content
            > h5.MuiTypography-root.productCardTitle.MuiTypography-h5,
        .MuiCardHeader-content
            > h6.MuiTypography-root.productCardTitle.MuiTypography-h6 {
            color: $white;
        }
    }
    .scrollable {
        max-height: 12rem;
        overflow-y: scroll;
    }
    .content {
        .MuiTypography-body1 {
            font-size: 2rem;
        }
    }
}
button.MuiButtonBase-root.MuiIconButton-root.addToCartButton {
    color: $lightSeaGreen;
    span.MuiIconButton-label > svg.MuiSvgIcon-root {
        fill: $lightSeaGreen;
    }
}
.MuiList-root.MuiList-padding {
    padding: 0;
    .MuiListItemText-root {
        margin: 0;
    }
}

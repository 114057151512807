@import '../../assets/scss/colors.scss';
.media {
    min-height: 21rem;
    width: 100%;
}

.subscriptionCard {
    padding-bottom: 1rem;
}
.grayRuler {
    border-bottom: 0.2rem ridge rgba($darkSlateGrey-80, 0.8);
    height: 0.2rem;
}
.MuiCardContent-root {
    padding: 16px 0 !important;
}
.subscriptionCard.MuiPaper-root .MuiCardHeader-root.cardHeader {
    background-color: $indianRed-80;
    border-bottom: 0.5rem solid $indianRed;
    width: 100%;
    padding: 16px !important;
    .MuiCardHeader-content
        > h6.MuiTypography-root.subscriptionCardTitle.MuiTypography-h6 {
        color: $white;
        text-transform: uppercase;
    }
}

.MuiCardMedia-root {
    background-size: contain !important;
}

.cardHeader {
    padding: 3rem;
}

@import '../../../assets/scss/colors.scss';
@import '../../../assets/scss/boxShadows.scss';
.cardContainer {
    padding: 0 15px !important;
}
.card {
    overflow: visible !important;
    color: rgba($blackColor, 0.87);
    width: 100%;
    border: 0;
    display: flex;
    position: relative;
    font-size: 0.875rem;
    min-width: 0;
    word-wrap: break-word;
    background: $whiteColor;
    box-shadow: 0 1px 4px 0 rgba($blackColor, 0.14);
    border-radius: 6px;
    flex-direction: column;
    background: $whiteColor;
    &_fullWidthHeader {
        margin: 0 15px;
        padding: 0;
        position: relative;
        padding: 15px;
        margin-top: -20px;
        border-radius: 3px;
        &.warning {
            background: linear-gradient( 60deg,
            $warningColor2,
            $warningColor3);
            box-shadow: $warningBoxShadow;
        }
        &.purple {
            background: linear-gradient(
                    60deg,
                    $purpleColor2,
                    $purpleColor3
                );
                box-shadow: $primaryBoxShadow;
        }
        &__heading {
            color: $whiteColor !important;
        }
        &__paragraph {
            color: $whiteColor !important;
        }
    }
    &_header {
        color: $whiteColor;
        margin: 0 15px;
        padding: 0;
        padding-bottom: 10px;
        position: relative;
        z-index: 3 !important;
        &__heading {
            margin-top: 10px !important;
        }
        &__icon {
            display: block;
            margin: -20px 15px 0 0;
            border-radius: 3px;
            padding: 15px;
            float: left;
            background-color: $grayColor1;
            &.rose {
                background: linear-gradient(60deg, $roseColor2, $roseColor3);
                box-shadow: $roseBoxShadow;
            }
            &.warning {
                background: linear-gradient(
                    60deg,
                    $warningColor2,
                    $warningColor3
                );
                box-shadow: $warningBoxShadow;
            }
            &.danger {
                background: linear-gradient(
                    60deg,
                    $dangerColor2,
                    $dangerColor3
                );
                box-shadow: $dangerBoxShadow;
            }
            &.purple {
                background: linear-gradient(
                    60deg,
                    $purpleColor2,
                    $purpleColor3
                );
                box-shadow: $primaryBoxShadow;
            }
            &.success {
                background: linear-gradient(
                    60deg,
                    $successColor2,
                    $successColor3
                );
                box-shadow: $successBoxShadow;
            }
            &.info {
                background: linear-gradient(60deg, $infoColor2, $infoColor3);
                box-shadow: $infoBoxShadow;
            }
        }
    }
    &_footer {
        border: 0;
        border-top: 1px solid $grayColor-11;
        margin: 0 15px 10px;
        margin-top: 20px;
        display: flex;
        padding: 0;
        align-items: center;
        padding-top: 10px;
        border-radius: 0;
        justify-content: space-between;
        background-color: transparent;
    }
}
.table {
    margin-bottom: 0;
    width: 100%;
    max-width: 100%;
    background-color: transparent;
    border-spacing: 0;
    border-collapse: collapse;
    &_tableHeadCell {
        color: inherit;
        &__tableCell {
            font-size: 1em;
        }
    }
}

.searchFormContainer {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: baseline;
    height: 15rem;
}
.menuContainer {
    .MuiInputBase-input {
        color: #fff !important;
    }
    .MuiFormLabel-root {
        color: #fff !important;
    }
}

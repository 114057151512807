@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import './assets/scss/colors.scss';

html,
body {
    margin: 0;
    font-size: 62.5%; // 1rem = 10px
    padding: 0;
    box-sizing: border-box !important;
    font-family: $fontLato, -apple-system, BlinkMacSystemFont, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media only screen and (max-width: 1200px) {
        font-size: 56.25%; // 1rem = 9px
    }

    @media only screen and (max-width: 900px) {
        font-size: 50%; // 1rem = 8px
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
header a,
header a:visited {
    color: $white;
    text-decoration: none;
}
a.red {
    color: $darkRed;
}
span.red {
    cursor: pointer;
}
.MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters.deleteHandle {
    display: flex;
}
.MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
    display: none;
}
header {
    .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
        display: flex;
    }
}
main h2.MuiTypography-h2,
main h3.MuiTypography-h3,
main h4.MuiTypography-h4,
main h5.MuiTypography-h5,
main h6.MuiTypography-h6,
main p.MuiTypography-p,
main span.MuiTypography-root,
main .MuiListItem-icon,
main .MuiListItem-text {
    font-weight: 500;
    line-height: 1.1;
    color: $darkSlateGrey;
    letter-spacing: normal !important;
}
// main h3.MuiTypography-h3 {
//     // font-size: 2.4rem;

// }
.MuiButton-label {
    font-weight: 600;
}
main,
footer {
    position: relative;
}
section {
    position: initial;
}
main a,
footer a {
    font-weight: 600;
    color: $white;
    text-decoration: none;
}
main a.dark {
    color: $darkSlateGrey;
}
main {
    /* top: 6.4rem; */
    top: 7.7rem;
}
main,
section {
    background-color: $white;
    padding: 0;
}
section.product-container {
    width: 80%;
}
.MuiGrid-container.full-height,
main,
section {
    width: 100%;
    min-height: 100vh;
}
button {
    width: initial;
}
footer {
    top: 7.7rem;
    min-height: 6rem;
    width: 100%;
}

.margin-left-2 {
    margin-left: 2rem !important;
}

.margin-left-half {
    margin-left: 0.5rem !important;
}

.margin-right-half {
    margin-right: 0.5rem !important;
}

tr {
    height: 2.5rem !important;
}

.th {
    max-width: 7.813rem;
    min-width: 7.813rem;
    padding: 1rem 0 !important;
    overflow-x: auto;
    white-space: nowrap;
    text-align: center !important;
}

td {
    height: 2.5rem !important;
    padding: 0.313rem !important;
    text-align: center !important;
}
th,
td {
    font-size: 1.8rem !important;
}

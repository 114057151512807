@import '../../assets/scss/colors.scss';

.MuiGrid-item.mt-minus3 {
    margin-top: -3rem !important;
}

.MuiTableRow-root.bg-yellow,
.MuiPaper-root.bg-yellow {
    /* #ece3d0 */
    color: $white;
    background-color: $coral;
}
#cart .MuiTableCell-head {
    font-weight: bold;
    font-size: large;
}
#checkout .MuiTableCell-head,
#cart .MuiTableCell-head,
.profile .MuiPaper-root h5.MuiTypography-h5,
.profile .MuiPaper-root span.MuiTypography-root {
    color: $white;
}

.labelText .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    color: $darkSlateGrey;
}
.profile .MuiPaper-rounded.circle,
.circle {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    margin: 0 auto;
    padding: 0;
}
.MuiPaper-root .MuiCardHeader-root {
    padding: 0;
}
.profile .MuiCardHeader-avatar,
.profile .MuiCardHeader-avatar .MuiAvatar-root {
    display: block;
    font-size: 6rem;
    margin: 0 !important;
    width: 6rem;
    height: 6rem;
}
.profile .MuiCardHeader-avatar .MuiSvgIcon-root {
    font-size: 6rem;
    color: $white;
    background-color: $forestGreen;
}
